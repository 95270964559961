<template>
  <span data-el="price">
    <span
      v-if="currencyPosition === 'before'"
      data-el="price-currency"
    >
      <slot
        :currency="currencySymbol"
        name="curency"
      >
        {{ currency }}
      </slot>
    </span>

    <span
      data-el="price-amount"
      :class="{ 'line-through': props.lineThrough }"
    >
      {{ formattedPrice }}
    </span>

    <span
      v-if="currencyPosition === 'after'"
      data-el="price-currency"
    >
      <slot
        :currency="currencySymbol"
        name="curency"
      >
        {{ currency }}
      </slot>
    </span>
  </span>
</template>

<script lang="ts" setup>
import { usePriceFormatter } from '../../composables/usePriceFormatter/usePriceFormatter'

const props = withDefaults(
  defineProps<{
    value: number | null // null will be converted to 0
    lineThrough?: boolean
  }>(),
  {
    value: null,
    lineThrough: false,
  },
)

const { value } = toRefs(props)
const { formattedPrice, currencySymbol, currencyPosition } = usePriceFormatter(value)

const currency = computed(() => {
  if (currencyPosition.value === 'before') {
    // eg. $10 (no space before)
    return currencySymbol.value
  }
  else {
    // eg. 10 zł (space before currency symbol)
    return ` ${currencySymbol.value}`
  }
})
</script>
