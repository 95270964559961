import { useStoreConfig } from '../useStoreConfig/useStoreConfig'

/**
 * Composable that returns formatted price (eg. 123,45 zł), currency symbol (eg. zł)
 * and currency position (before or after price value)
 * @param price - if null, returns 0
 */
function usePriceFormatter(price: Ref<number | null>) {
  const { currencyData, storeConfigData } = useStoreConfig()

  const localLang = ref<string | undefined>(storeConfigData?.value?.locale?.replace('_', '-'))
  const currencyCode = currencyData.value?.currencyCode
  const formatter = new Intl.NumberFormat(localLang.value, {
    style: currencyCode ? 'currency' : 'decimal',
    currency: currencyCode || undefined,
    currencyDisplay: 'symbol',
  })

  const priceParts = computed<Intl.NumberFormatPart[]>(() =>
    formatter.formatToParts(typeof price.value === 'number' ? price.value : 0),
  )

  /**
   * @description Returns formatted price without currency symbol
   * @example 1 234,56
   */
  const formattedPrice = computed<string>(() => {
    return priceParts.value
      .map((data) => {
        switch (data.type) {
          case 'currency':
            return ''
          default:
            return data.value
        }
      })
      .reduce((string, part) => string + part)
      .trim()
  })

  /**
   * @description Returns currency symbol
   * @example zł
   */
  const currencySymbol = computed<string>(() => {
    const formatParts = formatter.formatToParts(price.value ?? 0)
    let currency = ''
    for (const part of formatParts) {
      if (part.type === 'currency') {
        currency = part.value
        break
      }
    }

    return currency
  })

  /**
   * @description Returns currency position
   * @example 'before'
   */
  const currencyPosition = computed<'before' | 'after'>(() => {
    const currencyAtTheBeginning = priceParts.value[0].type === 'currency'
    return currencyAtTheBeginning ? 'before' : 'after'
  })

  return {
    formattedPrice,
    currencySymbol,
    currencyPosition,
  }
}

export { usePriceFormatter }
